@import '../../shared';

.loading-container {
  $component: &;

  position: relative;
  background-color: inherit;

  &--standalone {
    @include z-index(modal);

    background-color: $white;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden;

    #{$component}__content {
      position: absolute;
      bottom: calc(50% - 4rem);
      left: 0;
      right: 0;
    }
  }

  &--cancelable {
    #{$component}__content > div {
      max-width: 320px;
      margin: 0 auto;
      position: relative;
      top: 80px;
    }
  }

  &--flex-inline {
    display: inline-flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;

    #{$component}__content :nth-child(1) {
      margin-right: 1.5rem;
    }
  }

  &--flex-align {
    margin: 0 auto;
  }

  &--light {
    background-color: $red;
  }

  &--white {
    background-color: $white;
  }

  &--bottom-margin {
    margin-bottom: 20px;
  }

  &__spinner {
    @include z-index(modal);

    position: absolute;
    top: calc(50% - 1.25rem);
    left: calc(50% - 1.25rem);

    &--static {
      position: relative;
      top: 0;
      left: 0;
    }

    &--button {
      top: 50%;
      left: calc(100% + 20px);
      transform: translateY(-50%);
      max-width: 1.5rem;
    }
  }

  &__content {
    margin-top: 2rem;

    &--text-only {
      text-align: center;
    }
  }

  &.is-loading &__content {
    opacity: 0.5;
  }

  &.success {
    #{$component}__content {
      color: $success;
    }
  }

  &.error {
    #{$component}__content {
      color: $error;
    }
  }
}
