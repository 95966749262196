@import "../../shared";

.notification-list {
  &__header {
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $border-grey;
    margin-bottom: 1rem;
    padding: 1rem 0;
    min-height: 2.5rem;
  }

  &__header-submit {
    margin-left: auto;
  }

  &__header-label {
    margin-left: 1rem;
  }

  &__item-wrapper {
    display: flex;
    margin-bottom: 1rem;

    @include breakpoint(xl) {
      align-items: center;
    }

    .form-checkbox {
      margin-right: 1rem;

      @include breakpoint(xl) {
        margin-right: 2rem;
      }
    }

    .notification-item {
      width: 100%;
    }
  }
}


