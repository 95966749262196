.two-column-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;

  @include breakpoint(lg) {
    grid-template-columns: 1fr auto;
  }

  @include breakpoint(xl) {
    grid-template-columns: 1fr 1fr;
  }

  &__item {
    min-width: 20px;
  }

  &--align-end {
    @include breakpoint(xl) {
      justify-self: right;
      align-self: end;
    }
  }

  &--small-gap {
    grid-gap: 1rem;
  }

  .button-group--align-end {
    justify-self: stretch;

    @include breakpoint(xl) {
      justify-self: right;
      align-self: end;
    }
  }
}
