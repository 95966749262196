@import "../../1_settings/fonts";

.form-section {
  margin-bottom: 30px;

  &__title {
    border-bottom: 1px solid $light-grey;
    color: $light-black;
    display: block;
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
    margin-right: 0.5rem;
    padding: 0.625rem 0;
    font-family: $font;
  }

  & > .form-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    > * {
      flex: 0 1 100%;

      @include breakpoint(lg) {
        flex: 0 1 calc(33.33% - 20px);
      }
    }

    &__title {
      display: block;
      font-size: 1.25rem;
      line-height: 1.5rem;
      margin-bottom: 1rem;
      margin-right: 0.5rem;
      font-family: $font;
    }

    .form-element--textarea {
      background-color: $light-grey;
    }
  }
}
