.hide-on-mobile {
  display: none;

  @include breakpoint(lg) {
    display: block;
  }
}

.hide-on-desktop {
  display: block;

  @include breakpoint(lg) {
    display: none;
  }
}

.hide {
  display: none;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}
