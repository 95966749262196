.text {
  font-family: $font;
  color: $black;
  font-size: 1rem;
  line-height: 1.25em;
  font-weight: normal;

  &--grey {
    color: $grey;
  }

  &--success {
    color: $success;
  }

  &--bold {
    font-weight: bold;
  }

  &--xl {
    font-size: $font-size-xl;
  }

  &--l {
    font-size: $font-size-l;
  }

  &--ml {
    font-size: $font-size-m;

    @include breakpoint(sm) {
      font-size: $font-size-ml;
    }
  }

  &--m {
    font-size: $font-size-s;

    @include breakpoint(sm) {
      font-size: $font-size-m;
    }
  }

  &--s {
    font-size: $font-size-xs;
    line-height: 1.5em;

    @include breakpoint(sm) {
      font-size: $font-size-s;
    }
  }

  &--xs {
    font-size: $font-size-xs;
    line-height: 1.35rem;
  }

  &--red {
    color: $red
  }

  &--block {
    display: block;
  }

  &--spacing-xl {
    margin: 60px 0;
  }

  &--centered {
    text-align: center;
  }
}
