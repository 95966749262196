@import '../../2_tools/resets/select-reset';
@import '../../1_settings/colors';
@import "../../1_settings/fonts";

:root {
  .form-element {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 2px solid $form-border-grey;
    color: $black;
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: $font;

    &--dropdown {
      @include select-reset;

      background-size: auto;
      padding: 5px 2rem 3px 0;
      background-image: url("../../../images/chevron-down-regular.svg");
      background-repeat: no-repeat;
      background-position: 100% 0.15rem;
      color: $black;
      width: fit-content !important;
      max-width: 100%;

      option {
        font-family: $font;

        &:first-child {
          color: $grey;
        }

        &:not(:first-child) {
          color: $black;
        }
      }
    }

    &[disabled] {
      background-color: rgba($light-grey, 0.5);
      opacity: 1;
      cursor: not-allowed;
    }

    &--datepicker {
      display: block;
    }

    &--has-error {
      color: inherit;
    }

    &__unit {
      border-bottom: 2px solid $form-border-grey;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
    }

    &--align-right {
      text-align: right;
      padding-right: 10px;
    }

    &--textarea {
      box-sizing: border-box;
      padding: 0;
      white-space: break-spaces;
      resize: none;
    }
  }
}

.form-element-unit-wrapper {
  display: flex;
}
