@import "../../1_settings/layout";
@import "../../2_tools/general/breakpoints";

.button-group {
  display: flex;

  &--column {
    flex-direction: column;
  }

  &--align-end {
    @include breakpoint(sm) {
      justify-self: right;
      align-self: end;
    }
  }

  &--spacing-y {
    & > * + * {
      margin-top: 0.75rem;
    }
  }
}