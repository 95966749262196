.input {
  border: none;
  padding: 6px 8px;
  font-family: $font;

  &:focus {
    border: none;
    box-shadow: none;
    outline: none;
  }

  &--bg-grey {
    background-color: $light-grey;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
