@import "../../shared";

.page-header {
  $first-row-height: 3.5rem;

  background-color: $white;
  border-bottom: 1px solid $light-grey;
  padding: 0;
  position: fixed;
  top: 0;
  z-index: 10;
  left: 0;
  right: 0;
  height: $page-header-height-mobile;
  max-width: 100vw;
  width: 100%;

  @include breakpoint(md) {
    height: $page-header-height-tablet;
  }

  @include breakpoint(lg) {
    height: 4rem;
    padding: 1.25rem 0 0;
  }

  &__nav {
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    grid-template-areas:
      "logo list"
      "link link"
      "details details";
    grid-template-rows: $first-row-height auto auto;
    grid-gap: 1rem;
    max-width: $page-header-width;
    margin: 0.75rem auto;
    padding-left: $page-content-spacing-x;
    padding-right: $page-content-spacing-x;

    @include breakpoint(md) {
      grid-template-columns: auto;
      grid-template-rows: auto;
      grid-template-areas:
      "logo link details list";
      grid-gap: 1rem;
    }

    @include breakpoint(lg) {
      grid-template-columns: 1fr auto auto auto;
      grid-gap: 4rem;
    }
  }

  &__logo {
    grid-area: logo;
    height: 30px;
    align-self: baseline;

    @include breakpoint(md) {
      height: 40px;
      align-self: center;
    }

    svg {
      width: auto;
      height: 100%;
    }
  }

  &__account {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 3rem;
  }

  &__details {
    grid-area: details;

    &-name {
      margin-right: 0.5rem;

      @include breakpoint(lg) {
        display: block;
        margin-right: 0;
      }
    }

    &-last-login {
      display: block;
    }
  }

  &__link {
    grid-area: link;
    margin-top: -0.3rem;

    @include breakpoint(md) {
      align-self: center;
      margin-top: 0;
    }
  }

  &__icon-list {
    align-self: baseline;
    align-items: baseline;
    display: flex;
    flex-wrap: nowrap;
    grid-area: list;
    height: 40px;

    @include breakpoint(md) {
      align-self: center;
      margin-top: -0.5rem;
    }

    & > * + * {
      margin-left: 1em;
    }
  }

  &__icon-link {
    position: relative;
    display: block;
  }

  &__icon-bubble {
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    color: $white;
    background-color: $red;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
  }

  &__details {
    line-height: 1.125rem;
  }
}
