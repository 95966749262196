.flex {
  display: flex;
  flex-direction: column;

  @include breakpoint(sm) {
    flex-direction: row;
  }

  &--col {
    flex-direction: column;
  }

  &--justifiy-center {
    justify-content: center;
  }

  &--align-center {
    align-items: center;
  }

  &--margin-x {
    @include margin-h(10px);
  }

  &--margin-y {
    @include margin-v(16px);
  }

  &--grow {
    flex: 1;
  }
}
