@import '../../shared';
@import '../../6_components/general/text';

.dnd-file-list {
  margin-bottom: 16px;

  &-item {
    border-bottom: 1px solid $grey;
  }

  &__info {
    border-bottom: 1px solid $grey;
    font-size: 1rem;
    padding-bottom: 8px;
    margin-bottom: 0;
  }
}

.dnd-file-list-item {
  align-items: center;
  background: rgba($white, 0.8);
  display: flex;
  flex: 1;
  padding: 4px 0;
  top: auto !important;
  left: auto !important;

  &__handle {
    display: inline-block;
    cursor: move;
    padding: 12px;
    margin-right: 12px;
  }

  &__icon {
    color: #666;
    min-width: 24px;
    min-height: 24px;

    &:active,
    &:focus {
      border: none;
      box-shadow: none;
      outline: none;
    }
  }

  &__text {
    @extend .text;

    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__image {
    object-fit: cover;
    object-position: center;
    min-width: 40px;

    &--pdf {
      color: $red;
      height: 40px;
      padding: 10px;
      width: 40px;
    }
  }
}
