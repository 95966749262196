@import '../../1_settings/colors';
@import '../../1_settings/fonts';
@import '../../1_settings/transitions';

.button {
  $component: &;

  background-color: $light-grey;
  cursor: pointer;
  color: black;
  font-family: $font;
  font-weight: normal;
  font-size: 1.125rem;
  outline: 0;
  padding: 11px 24px;
  text-align: center;
  text-decoration: none;
  transition: all $duration $easing;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 0;
  width: 100%;
  border-radius: 12px;

  @include breakpoint(sm) {
    width: auto;
  }

  &__wrapper {
    margin-bottom: 60px;
  }

  &--small {
    font-size: 1rem;
    padding: 6px 14px 6px 14px;
    border-radius: 8px;
  }

  &--icon,
  &--link {
    background: transparent;
    font-size: 1rem;
  }

  &--icon {
    width: auto;

    & > svg {
      color: $red;
      transition: color $duration $easing;
    }

    &:hover > svg {
      color: $light-red;
    }
  }

  &--link {
    padding: 0;
  }

  &--primary-no-border {
    color: $red;

    &:hover {
      color: $light-red;
    }
  }

  &--primary {
    background-color: $red;
    color: $white;
    border: 2px solid $red;

    &:hover {
      background-color: $light-red;
      border: 2px solid $light-red;
    }
  }

  &--cta-simple {
    padding: 0;
    margin: 0;
    background-color: transparent;
  }

  &--ghost {
    background-color: transparent;
    color: $red;
    border: 2px solid $red;

    &:hover {
      color: $light-red;
      border: 2px solid $light-red;
    }
  }

  &--disabled-low-opacity {
    opacity: 0.2;
    pointer-events: none;
  }

  &--wide {
    width: 100%;
  }

  &--no-margin {
    margin-bottom: 0;
  }

  &--margin-right-only {
    margin: 0 1.5rem 0 0;
  }

  &--center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &--photo {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    #{$component}__image,
    #{$component}__icon {
      margin-bottom: 18px;
    }

    #{$component}__text {
      line-height: 18px;
    }
  }

  &--disabled,
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &--align-right {
    margin-left: auto;
    margin-top: 2rem;
    display: block;
  }

  &--bottom-space {
    margin-bottom: 3rem;
  }
}
