.document-upload-form {
  display: flex;
  flex-direction: column;

  &__queue {
    margin: 1.25rem 0;
  }

  &__hint {
    margin: 2rem 0;
  }

  &__file {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    line-height: 1.5;
    padding: 10px 0;

    &:not(:last-child) {
      border-bottom: solid 1px lighten($grey, 30%);
    }
  }

  &__file-name {
    flex: 1 0 auto;
  }

  &__file-size {
    flex: 0 auto;
  }

  &__delete {
    cursor: pointer;
    width: 20px;
    padding-left: 16px;
  }

  &--disabled .file-drop-upload__dropzone {
    opacity: 60%;
  }
}
