@import "../../1_settings/fonts";

.form-label {
  display: block;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-bottom: 1rem;
  margin-right: 0.5rem;
  font-family: $font;

  &--flex-row {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
