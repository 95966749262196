@import "../../shared";

.modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.modal-background {
  background-color: #00000047;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.modal {
  position: absolute;
  background: white;
  padding: 20px;
  left: calc(50% - 1.5rem);
  top: 50%;
  max-width: 600px;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: calc(100% - 5rem);
  overflow-y: auto;
  max-height: calc(100vh - 80px);

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  &__header-logo {
    svg {
      height: 2.5rem;
      width: auto;
    }
  }

  &__close {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: auto;

    svg {
      pointer-events: none;
    }
  }

  &__logo {
    width: 200px;
    height: 48px;
  }

  &__content {
    margin-bottom: 1.5rem;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;

    .button {
      margin: 0.5rem;
    }

    &--spread {
      justify-content: space-between;

      .button {
        margin: 0;
      }
    }
  }

  &__headline-icon {
    display: block;
    height: auto;
    width: 48px;

    &--centered {
      margin: 0 auto;
    }
  }

  &__content-loader {
    background-color: $white;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
  }

  &__content-image {
    width: 100%;
    height: auto;
  }

  &__definition-list {
    background-color: $light-grey;
    padding: 2rem 2rem;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-row-gap: 1rem;

    dt {
      font-weight: bold;
    }

    dd {
      margin-left: 2rem;
    }
  }
}
