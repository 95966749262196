.border-box {
  border: 1px solid $light-grey;
  padding: 1.5rem;

   span {
    display: block;
  }

  & > * + * {
    margin-top: 1rem;
  }
}