@import '../../shared';

.key-value-box {
  padding: 1rem 1.5rem;
  display: grid;
  grid-template-columns: 1fr auto;
  background-color: $light-grey;
  grid-row-gap: 0.5rem;
  grid-column-gap: 2rem;
  width: auto;
  margin: 0;

  dd {
    margin-inline-start: 0;
  }
}
