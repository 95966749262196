@import '../../1_settings/colors';
@import '../../1_settings/fonts';
@import '../../2_tools/resets/link-reset';

.link {
  @include link-reset();

  font-family: $font;
  font-weight: bold;
  font-size: inherit;
  line-height: 1.45;
  color: $red;
  transition: color 0.2s;
  will-change: color;

  &:hover {
    color: rgba($red, 0.6);
  }

  &--with-chevron {
    &, &-reverse {
      display: inline-flex;
      align-items: center;
      font-weight: normal;
      position: relative;
      font-size: $font-size-m;
      line-height: $font-size-m;
      padding-right: 1.5rem;
      white-space: nowrap;

      &::before {
        position: absolute;
        content: "";
        height: 1.125rem;
        width: 1.125rem;
        right: 0;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: right center;
        background-image: url("../../../images/chevron-right.svg");
        transition: opacity 0.2s;
      }

      &:hover::before {
        opacity: 0.6;
      }
    }

    &-reverse {
      padding-right: 0;
      padding-left: 1.5rem;

      &::before {
        right: auto;
        left: 0;
        background-position: left center;
        background-image: url("../../../images/chevron-left.svg");
      }
    }
  }
}
