@import '../../shared';
@import '../general/buttons';
@import "../../1_settings/fonts";

.file-drop-upload {
  &__dropzone {
    @include border-radius(normal);

    align-items: center;
    background-color: $white;
    border: 2px dashed $grey;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 200px;
    transition: 0.2s ease;
    position: relative;

    &.disabled {
      opacity: .6;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
  }

  &__label {
    margin-left: 1rem;
    font-family: $font;
  }

  &__button {
    @extend .button !optional;

    margin-top: 2rem;
    margin-bottom: 0;

    @include breakpoint(sm) {
      margin-top: 0;
      margin-left: 2rem;
    }
  }

  &.drag-active {
    .file-drop-upload__dropzone {
      border-color: $red;
    }
  }

  &__reject-warning {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-weight: bold;
    text-align: center;
    background-color: rgba($white, 0.8);
    transition: 0.2s ease;
    color: $red;
    padding: 1rem;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    > span {
      display: block;
      padding: 0.5rem 1rem;
      background-color: $white;
    }
  }

  &.drag-reject {
    .file-drop-upload__reject-warning {
      opacity: 1;
    }
  }
}
