@import '../../shared';

.table {
  table,
  &__table {
    width: 100%;
    table-layout: auto;
    border-collapse: collapse;
  }

  td, th {
    font-family: $font;
    color: $black;
    font-size: 1rem;
    line-height: 1.25em;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: left;

    svg {
      float: right;
      margin-left: 1rem;
    }
  }

  tr {
    min-height: 42px;
    height: 42px;
    padding: 0.5rem 0;
  }

  thead {
    th {
      color: $red;
      text-align: left;
      white-space: nowrap;
    }
  }

  tbody {
    th {
      color: $black;
      white-space: nowrap;
    }
  }

  &__column-strech {
    width: 100%;
  }

  &__tag {
    padding: 2px 4px;
    border-radius: border-radius(normal);
    color: $white;
    font-size: 14px;

    &--done {
      background-color: $skin;
    }

    &--wip {
      background-color: $brown;
    }

    &--open {
      background-color: $alert;
    }
  }

  &__cell-with-icon {
    flex-wrap: nowrap;
    align-items: center;

    &-icon-container {
      vertical-align: sub;
    }

    &--stretch {
      width: 100%;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }

  &__cell-with-icons {
    display: inline-flex;
  }

  &__cell-with-icon,
  &__cell-with-icons {
    & > * + * {
      margin-left: 1rem;
    }
  }

  &__cell-with-dropdown {
    margin-bottom: 0;
  }

  &--simple {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  &--advisor-page {
    .rdt_Table {
      margin-left: 0 !important;
      margin-right: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  &--no-overflow {
    margin-left: 0;
    margin-right: 0;
  }

  &__cell-dropdown {
    width: 100%;

    .form-element {
      width: 100% !important;
    }
  }
}

.rdt_TableHeader {
  & > div {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.rdt_Table {
  //margin-left: -$page-content-spacing-x;
  //margin-right: -$page-content-spacing-x;
  //padding-left: $page-content-spacing-x;
  //padding-right: $page-content-spacing-x;


  .rdt_TableHeadRow {
    color: $red;
    border: 0;
    min-height: 3rem;
    border-bottom: 3px solid $grey;
  }

  .rdt_TableBody .rdt_TableRow {
    border: 0;
    min-height: 3rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    &:not(:last-of-type) {
      border-bottom: 1px solid #C6C6C6;
    }
  }

  .rdt_TableCell {
    font-family: $font;
    font-weight: normal;
    font-size: $font-size-m;
    line-height: $font-size-xl;
  }

  .rdt_TableCol {
    font-family: $font;
    font-weight: bold;
    line-height: $font-size-xl;
    font-size: $font-size-m;
    color: $red;
  }

  .rdt_TableCol {
    .__rdt_custom_sort_icon__ {
      margin-left: 0.25rem;

      svg {
        height: $font-size-m;
        position: relative;
        bottom: -1px;
      }
    }
  }
}

.rdt_Pagination {
  font-family: $font;
  font-weight: normal;
  font-size: 1rem;
  line-height: $font-size-s;
  border-top-color: #9292921f !important;
  margin-top: 1rem;
}


