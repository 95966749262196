@import "../../shared";

.notification-item {
  position: relative;
  background-color: $white;
  border: 1px solid $border-grey;
  padding: 1rem;

  &--offen {
    border-left: none;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 0.5rem;
      background-color: $red;
    }
  }

  .loading-container {
    display: none;

    &.is-loading {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($white, 0.5);
    }

    .spinner {
      background-color: $white;
      position: static;
      margin: auto;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  &__title {
    font-weight: bold;
    color: $black;
  }

  &__date {
    margin-left: 1rem;
  }

  &__content {
    display: flex;
    flex-direction: column;

    @include breakpoint(xl) {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__changes-list {
    margin-top: 1.25rem;
    margin-left: 1.875rem;
    list-style: disc;

    &-item {
      margin-bottom: 0.625rem;
    }
  }

  &__button {
    margin-top: 2rem;

    @include breakpoint(xl) {
      margin-top: 0;
      margin-left: 2rem;
    }
  }
}

