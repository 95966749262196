@import "../../shared";

.tabs {
  $tabs: &;

  display: flex;
  flex-direction: column;

  @include breakpoint(md) {
    flex-direction: row;
    justify-content: space-between;
  }

  & > * + * {
    margin-top: 0.75rem;

    @include breakpoint(md) {
      margin-top: 0;
    }
  }

  &__wrapper {
    width: 100%;

    &:last-child .tabs__button {
      border-right: none;
    }

    &:first-child .tabs__button  {
      border-left: none;
    }
  }

  &__button {
    cursor: pointer;
    padding: 1rem 3rem 1rem 1rem;
    width: 100%;
    transition: all $duration $easing;
    background-color: $light-grey;
    position: relative;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @include breakpoint(md) {
      border-bottom: 2px solid $border-grey;
      border-left: 1px solid $border-grey;
      border-right: 1px solid $border-grey;
      padding: 0.75rem;
      text-align: center;
    }

    &:after {
      content: "";
      position: absolute;
      background-image: url("../../../images/icon-plus.svg");
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      top: 1rem;
      right: 1rem;
      transform: rotate(0);
      transition: transform linear 0.25s;

      @include breakpoint(md) {
        content: none;
      }
    }

    @mixin activeButtonIndicator() {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        border-top: 2px solid $red;
      }
    }

    @include breakpoint(md) {
      &:hover,
      &:focus,
      &:focus-within,
      &.is-active {
        @include activeButtonIndicator();

        background-color: $white;

        #{$tabs}__topic {
          color: $black;
        }
      }
    }

    &:focus,
    &:focus-within,
    &.is-active {
      @include activeButtonIndicator();

      &:after {
        content: "";
        background-image: url("../../../images/icon-minus.svg");
        transform: rotate(180deg);
        bottom: 50%;

        @include breakpoint(md) {
          content: none;
        }
      }
    }

    &.is-active {
      border-bottom: 2px solid $white;
    }
  }

  &__topic {
    font-family: $font;
    line-height: 1.25em;
    hyphens: auto;
    margin: 0;
    font-size: $font-size-m;
    color: $black;
    pointer-events: none;

    @include breakpoint(sm) {
      color: $grey;
    }
  }
}
