/**
  Funktion um auf die Map mit Z-Indizes zuzugreifen.
  Die Map selbst, wird mit dieser Funktion in der Main.css geladen
  und muss nicht separat importiert werden.
 */
@function z-index($key) {
  @return map-get($z-index, $key);
}

/**
  Dieses Mixin ergänzt die gleichnamige Funktion und vereinfacht den Zugriff auf die Z-Index-Map,
  bzw. die Verwendung der Funktion.
 */
@mixin z-index($key) {
  z-index: z-index($key);
}
